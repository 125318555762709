<script>
import BoCard from "@/components/backend/BoCard.vue"
import BoSearch from "@/components/backend/BoSearch.vue"
import BoPageTitle from "@/components/backend/BoPageTitle.vue"

import { mapState } from 'vuex'
import Config from './Config'
import Gen from '@/libs/Gen.js'
import $ from 'jquery'
import _ from 'lodash'

export default {
  components: {
    BoCard, BoSearch, BoPageTitle
  },
  data() {
    return {
      tableTransitionProps: {
        name: 'table-transition'
      },
      statusOptions: [
        { text: 'Aktif', value: 'Y' },
        { text: 'Nonaktif', value: 'N' },
      ],
      statusFilters: [
        { label: 'All Status', value: '' },
        { label: 'Aktif', value: 'Y' },
        { label: 'Nonaktif', value: 'N' },
      ],
      
      filter: {},
      row: {},
      websiteHero:{},
      websiteLanding:{},
      website404:{},      
      input:{},
      mrValidation: {},
      data: {data: false},
      duration:35000,
      pattern:{
        name:/^[a-zA-Z '.,]+$/,
        username:/^[a-zA-Z0-9_.]+$/,
        phone:/^(?=[1-9])[0-9]+$/,
      },
      imgHash: "",
      isFound: true,
      mrSuperior: [],
      mrDepartment: [],
      loadingTable: false,
    }
  },
  computed: {
    isMobile() { return window.innerWidth < 768 },
    isTablet() { return window.innerWidth >= 768 && window.innerWidth < 992 },
    isDesktop() { return !this.isMobile && !this.isTablet },
    breadcrumbs() { return this.$route.meta.breadcrumbs },
    objName() { return this.$route.meta.objName },
    hasCta() { return this.$route.meta.hasCta },
    routeName() { return this.$route.name },

    ...mapState({
      production: state => state.production,
      apiUrl: state => state.apiUrl,
      baseUrl: state => state.baseUrl,
      foBaseUrl: state => state.foBaseUrl,
      webTitle: state => state.webTitle,
      userToken: state => state.userToken,
      user: state => state.user,
    }),
    loadingOverlay:{
      get(){ return this.$root.var.loadingOverlay },
      set(v){ return this.$root.var.loadingOverlay = v },
    },
    Config(){ return Config },

    pageSlug(){ return this.$route.params.pageSlug },
    pageId(){ return this.$route.params.pageId },
    isAdd(){ return this.pageSlug == "add" },
    isPageSlugTXT(){ 
      return this.pageSlug == "add" ? "add" : "edit" 
    },
    isList(){ return !this.pageSlug },
    isSorting(){return this.$route.query.sorting&&this.isList},
    isDelete(){return this.$route.query.delete&&this.isList},

    dataList(){return this.data.data },

    pageTitle(){return this.$route.meta?.pageTitle||""},
    pageTitleForm(){return (this.pageSlug == 'add' ? 'Add' : 'Edit')+' '+this.pageTitle},
    modulePage(){return this.$route.meta.menu},
    
    pageNo: {
      get:function() {
        return this.filter.page||this.$route.query.page||1
      },
      set:function(newValue) {
        this.$set(this.filter, "shown", this.perPage)
        this.$set(this.filter, "page", newValue)
        this.$router.push({
          name:this.modulePage,
          query:_.clone(this.filter)
        }).catch(()=>{})
      }
    },

    perPage:{
      get:function() {
        return this.$route.query.shown||this.filter.shown||Config.dataShown
      },
      set:function(newValue) {
        this.pageNo=1
        this.$router.push({
          name:this.modulePage,
          query:{shown:newValue}
        }).catch(()=>{})
      }
    },

    apiParams(){ return _.assign(_.clone(this.filter), _.clone(this.apiQuery||{})) },
    apiQuery(){return this.$route.query||{}},

    notFound(){
      if (this.data.data === false) return false
      if (this.data.data.length > 0) return false
      if (this.$route.query.search) {
        return "Search with keyword '" + this.$route.query.search + "' not found"
      }
      return this.pageTitle + " not found"
    },
    filterNotEmpty(){
      return $.grep(Object.keys(this.filter)||[], attr=>{
        return this.filter[attr] && attr!='page'
      }).length >= 1
    },
    passToSubComp(){
      return {
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
      }
    },
    
    sPerawat(){return 1},
    sDokter(){return 2},
    sFisio(){return 3},
    sPPA(){return 5},
    sRM(){return 6},
    sVoid(){return 99},
    
	  uPerawat(){
			return 3
		},
		uDokter(){
			return 5
		},
		uDokterFisio(){
			return 7
		},

		uLab(){
			return 12
		},
		uDokterGizi(){
			return 6
		},
		uFisio(){
			return 9
		},
		uRadiologi(){
			return 13
		},
		uFarmasi(){
			return 11
		},
		uRekamMedis(){
			return 14
		},
		uAdmin(){
			return 17
		},
    uAdmisi(){
      return 18
    },
    baseUrlFO(){
      return process.env.VUE_APP_BASE_URL
    },
    tokenPostDisplay(){
      return process.env.VUE_APP_TOKEN_DISPLAY
    },

    isRegistration(){
      return process.env.VUE_APP_IS_REGISTRATION || false
    },

    isFingerPrint(){
      return process.env.VUE_APP_FINGER_PRINT_DISPLAY || 'http://localhost:82/FingerPrint/'
    } 

  },
  mounted() {
    this.filter = _.clone(this.$route?.query)
    this.menuCheck()
    
    $("input").attr("autocomplete","off")

    $('body').on('keydown', "input[type='text']", function(e) {
      if (e.which === 32 &&  e.target.selectionStart === 0) {
        return false
      }  
    })

    $('body').on('keydown', "input[type='tel']", function(e) {
      if (e.which === 32 &&  e.target.selectionStart === 0) {
        return false
      }  
    })
    
    $('body').on('keydown', "input[type='password']", function (e) {
        if (e.which === 32 && e.target.selectionStart === 0) {
            return false
        }
    })
    
    $('body').on('keydown', "input[type='email']", function(e) {
      if (e.which === 32 &&  e.target.selectionStart === 0) {
        return false
      }  
    })
    
    $('body').on('keydown', "input[type='number']", function(e) {
      if (e.which === 32 &&  e.target.selectionStart === 0) {
        return false
      }  
    })
    
    $('body').on('keydown', "textarea", function(e) {
      if (e.which === 32 &&  e.target.selectionStart === 0) {
        return false
      }  
    })
  },
  methods: {
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    removeMultiSpace(e,fields,row = 'row'){
      var val = e.replace(/\s\s+/g, ' ')
      this[row][fields] = val
    },
    removeMultiSpaceDebArr(e,fields,row='row',k){
      var val = e.target.value.replace(/\s\s+/g, ' ')
      this[row][k][fields] = val  
    },
    removeMultiSpaceDeb(e,fields,row = 'row'){
      var val = e.target.value.replace(/\s\s+/g, ' ')
      this[row][fields] = val
    },
    removeWildCharDeb(e){
      let regex = /^[^[\]{}*!?^%#\/^/\\#?]*$/
      if (!regex.test(e.target.value)) {
        return e.target.value = e.target.value.slice(0, -1)
      }
    },
    removeMultiSpace2(e,fields,row = 'row'){
      var val = e.target.value.replace(/\s\s+/g, ' ')
      this[row][fields] = val
    },
    telInput(e,fields,row = 'row'){
      let val = e.target.value
      if(val[0] == 0 || val[0] == "0"){
        val = val.slice(1)
      }
      this[row][fields] = val
    },
    telInputArr(e,fields,row='row',k){
      let val = e.target.value
      if(val[0] == 0 || val[0] == "0"){
        val = val.slice(1)
      }
      this[row][k][fields] = val
    },
    onlyNumInputArr(e,fields,row='row',k){
      let regex = /^[0-9]*$/
      let val = e.target.value
      if (!regex.test(val)) {
        val = e.target.value.slice(0, -1)
      }
      this[row][k][fields] = val
    },
    onlyNumInput(e,fields,row='row'){
      let regex = /^[0-9]*$/
      let val = e.target.value
      if (!regex.test(val)) {
        val = e.target.value.slice(0, -1)
      }
      this[row][fields] = val
    },
    removeWildChar2(e){
      let regex = /^[^[\]{}*!?^%#\/^/\\#?]*$/
      if (!regex.test(e.target.value)) {
        return e.target.value = e.target.value.slice(0, -1)
      }
    },
    onlyNumDeb(e){
      let regex = /^[0-9]*$/
      if (!regex.test(e.target.value)) {
        return e.target.value = e.target.value.slice(0, -1)
      }
    },
    autoFill(from,to, row = 'row'){
      this[row][to] = this[row][from]
    },
    removeMultiSpaceAndAutoFill(e,fields,row = 'row', to){
      var val = e.replace(/\s\s+/g, ' ')
      this[row][fields] = val
      this[row][to] = this[row][fields]
      let regex = /^[^[\]{}*!?^%#\/^/\\#?]*$/
      if (!regex.test(this[row][to])) {
        this[row][to] = this[row][to].slice(0, -1)
      }
    },


    menuCheck(){
      setTimeout(()=>{
        if(this.user && !this.$route.meta.isDisplay){
          if(this.user.canManageAll == "N"){
            setTimeout(()=>{
              if(this.$route.name !== 'AccountSetting' && this.$route.name !== 'Notification' && this.$route.name !== 'RekamMedis' && this.$route.name !== 'VerifikasiRM' && this.$route.name !== 'Farmasi'){      
                let ind = this.user.menuRole.findIndex(x=>x.menu == this.$route.name)
                if(ind == -1){
                  this.$router.push({name : 'Dashboard'}).catch(()=>{})
                }
              }
            },2000)
          }
        }
      },250)
    },
    validateModuleRoleCrud(){
      if(this.$route.name !== 'AccountSetting'){
        if(this.isPageSlugTXT == 'add' && !this.moduleRole('add')){
            this.$router.push({name : this.$route.name}).catch(()=>{})
        }
        if(this.isPageSlugTXT == 'edit' && !this.moduleRole('edit')){
            this.$router.push({name : this.$route.name}).catch(()=>{})
        }
      }
    },
    getRelativeDayInWeek(d,dy) {
      d = new Date(d)
      var day = d.getDay(),
      diff = d.getDate() - day + dy
      return new Date(d.setDate(diff))
    },
    implode(a, b){ 
      if(typeof b == 'string') b = JSON.parse(b)
      return b.join(a)
    },
    download(path, optParams = {}){
      let reqUrl = process.env.VUE_APP_API_URL + "/api/bo/get/"+this.modulePage

      let params = Object.assign({export:1, token: Gen.getCookie("bo_auth")||"", download: path}, optParams, this.$route.query)
      params = new URLSearchParams(params).toString()

      reqUrl += '?'+params

      window.open(reqUrl, '_blank')
    },
    isType(data){
      return typeof data
    },
    apiGet(params = {}){
      if(this.modulePage == 'VerifikasiRM' || this.modulePage == 'Farmasi'){        
        if(!this.pageSlug || !this.$route.query.regId){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }
      }
        
      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")
      
      if(params.slugs) slugs = params.slugs
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      
      Gen.apiRest(
        "/get/"+this.modulePage+
        slugs, 
        {
          params: Object.assign({}, paramsQuery, params.query||{})
        }
      ).then(res=>{
        this.loadingOverlay = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })

        if(!this.isFound){
          if(this.modulePage == 'RekamMedis' || this.modulePage == 'VerifikasiRM' || this.modulePage == 'Farmasi'){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }else{
            this.$router.push({name : this.$route.name}).catch(()=>{})
          }
        }

        if(!this.isList){
          this.setFocusForm()
        }
        setTimeout(()=>{
          $('.label_error').hide()
        },1000)
        if(params.callback) params.callback()
      }).catch(()=>{
        this.loadingOverlay = false
      })
    },

    setFocusForm(){
      if(this.$refs.VForm.$el){
        let el = $(this.$refs.VForm.$el)
        if(el.find(':input:not([type=hidden])').length){
          el.find(':input:not([type=hidden]):first')
        }else if(el.find('textarea').length){
          el.find('textarea:first')
        }
      }
    },
    setTitle(){
      $("title").html(this.pageTitle+' - '+this.webTitle)
    },
    doFilter(urlOnly=false){
      if(this.exportType && this.filter){
        let f = Object.values(this.filter).filter(attr=>attr)
        if(f.length>=1){ 
          this.exportType = 'filtered'
        }else{
          this.exportType = 'all'
        }
      }
      let url = {
        name:this.$route.name,
        params: (this.$route.params||{}),
        query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
      }
      
      if(urlOnly) return url
      this.pageNo=1
      this.$router.push(url).catch(()=>{})
    },
    doSortBy(){
      let url = this.doFilter('urlOnly')
      url.query = Object.assign(url.query, {sortByField:this.sortBy, sortByType:this.sortDesc?'desc':'asc'})
      this.$router.push(url).catch(()=>{})
    },
    doReset(){
      const filterKeys = Object.keys(this.filter)
      const querys = _.assign(_.omit(this.$route.query||{}, filterKeys), {page:1})

      this.filter={}
      this.$router.push({name:this.$route.name,query:querys}).catch(()=>{})
    },
    doChangeStatus(k,v, params={}){
      let id = v[this.idKey]
      let changeStatus = v[this.statusKey] == "Y" ? "N" : "Y"
      this.$swal({
        title: `Ubah Status ke ${changeStatus=='N' ? 'Nonaktif' : 'Aktif'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Ya, ubah status ke ${changeStatus=='N'? 'Nonaktif' : 'Aktif'}!`,
        cancelButtonText:  `Tidak, kembali`,        
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.loadingOverlay=true

          let slugs=""
          if(params.slugs) slugs = params.slugs

          Gen.apiRest(
            "/do/"+this.modulePage+slugs,
            {data:{type:'status',id:id,status:changeStatus}}, 
            "POST"
          )
            .then(()=>{
              this.loadingOverlay = false 
              v[this.statusKey] = changeStatus        
              this.$swal({
                title: `Status Diubah Menjadi ${changeStatus=='Y'? 'Aktif' : 'Nonaktif'}.`,
                icon: 'success',
              })
              this.apiGet()
            })
            .catch(err=>{ 
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.message = err.response.data.message
                  err.title = err.response.data.title
                  err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })
        }
      })
    },
    doDelete(k,v, params={}){
      console.log(k,v)
      if(v?.related == 'Y'){
        return this.$swal({
          icon: 'error',
          title: "Anda Tidak Dapat Hapus Level ini",
          text: 'Data Level sedang digunakan oleh user lain'
        })
      }

      let id = v[this.idKey]
      let obj = params.obj||this.$route.meta.objName
      this.$swal({
        title: 'Hapus Data ' + obj + '?' || 'Data' + '?',
        text: "Anda tidak akan dapat memulihkan data ini!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, Hapus!',
        cancelButtonText: 'Kembali',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.loadingOverlay = true
          this.openForm = false

          let slugs=""
          if(params.slugs) slugs = params.slugs
      
          Gen.apiRest("/do/"+this.modulePage+slugs,{data:{type:'delete',id:id}}, "POST")
            .then((resp = {})=>{
              this.apiGet(params)
              //let obj = this.$route.meta.objName
              this.$swal({
                title: resp.data.message || 'Deleted Successfully!',
                icon: 'success',
              })
            })
            .catch(err=>{ 
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response.data.title
                  err.message = err.response.data.message
                  err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })        
        }
      })
    },
    reparse(js){
      return $.parseJSON(JSON.stringify(js))
    },
    doRest(slug, form, callback){
      if(this.loadingOverlay) return
      this.loadingOverlay = true 

      var data = $.parseJSON(JSON.stringify(form))
      var btnSubmit = $(".btn-loading")
      var prevHtml = btnSubmit.html()

      if($(".infoHTML").length){
        $(".infoHTML").removeClass('alert alert-danger alert-warning')
        $(".infoHTML").html('')
      }

      btnSubmit.html()
      Gen.apiRest(
        "/do/"+this.modulePage, 
        {data: _.assign({params:this.apiParams}, {type:slug}, data)}, 
        "POST"
      ).then(res=>{
          this.loadingOverlay = false
          let resp = res.data

          btnSubmit.html(prevHtml)

          resp.statusType = 200
          this.doSetAlertForm(resp)
          
          if(callback){
            callback(resp)
          }

          setTimeout(()=>{ this.message="" },15000)
      }).catch(err=>{
          this.loadingOverlay = false
          btnSubmit.html(prevHtml)
          if(err){
              err.statusType = err.status
              err.status = "error"
              err.title = err.response?.data?.title
              err.message = err.response?.data?.message
              err.messageError = err.message
          }

          this.doSetAlertForm(err)
          if(callback){
            let resp = err
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data),(i,v)=>{
                  msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                })
                return this.$swal("Blocked With Validation!", msg)
              }else{
                return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            }else if(resp.response.status==400){
              return this.$swal({
                title: resp.response.data.title||resp.response.data.message,
                html: resp.response.data.title?resp.response.data.message:"",
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
      })
    },
    doSubmit(action, input, callback=false, method="POST", IDFORM='VForm', refs=false){
      const refsObj = refs||this.$refs
      refsObj[IDFORM].validate().then(success => {
        if (!success) {
          setTimeout(()=>{
              let inv = []
              let el = document.querySelectorAll('.label_error')
              for(let i = 0; i < (el||[]).length; i++){
                  if(el[i].style.display !== 'none'){
                      inv.push(el[i].id)
                  }
              }
              if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
          },500)
          return
        }
        
        if(this.loadingOverlay) return
        this.loadingOverlay = true 

        var data = $.parseJSON(JSON.stringify(input))
        var btnSubmit = $(".btn-loading")
        var prevHtml = btnSubmit.html()

        if($(".infoHTML").length){
          $(".infoHTML").removeClass('alert alert-danger alert-warning')
          $(".infoHTML").html('')
        }

        btnSubmit.html()

        Gen.apiRest(action, {data: $.extend({params:this.apiParams}, data)}, method).then(res=>{
            this.loadingOverlay = false
            let resp = res.data

            btnSubmit.html(prevHtml)

            resp.statusType = 200
            this.doSetAlertForm(resp)
            
            if(callback){
              callback('success', resp)
            }

            setTimeout(()=>{ this.message="" },15000)
        }).catch(err=>{
            this.loadingOverlay = false
            btnSubmit.html(prevHtml)
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.title = err.response?.data?.title
                err.message = err.response?.data?.message
                err.messageError = err.message
            }

            this.doSetAlertForm(err)
            if(this.failReset)
                this.$nextTick(() => {
                    refsObj[IDFORM].reset()
                    this.message=""
                })
                
            if(callback){
              callback('error', err)
            }
        })
      })
    },
    doSubmitCRUD(IDForm='VForm', refs=false, typeSubmit=false, params={}){
      return this.doSubmit(
          "/do/"+this.modulePage, 
          _.assign(
            {type:typeSubmit||(this.isAdd?'add':'update')}, 
            {id: (this.pageId||this.pageSlug)}, 
            _.clone(this.row)
          ), 
          (type, resp)=>{
            if(type=='success'){
              setTimeout(()=>{
                this.$router.push({name:this.$route.name, params:params }).catch(()=>{})
              },1000)
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                  })
                  return this.$swal("Blocked With Validation!", msg)
                }else{
                  return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.status==400){
                return this.$swal({
                  title: resp.response.data.title||resp.response.data.message,
                  html: resp.response.data.title?resp.response.data.message:"",
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IDForm, refs
      )
    },
    eType(e){return $.type(e)},
    doDownloadTemplate(name){
      let reqUrl = this.apiUrl + "/template"
      reqUrl += "/"+name
      window.open(reqUrl, '_blank')
    },
    doExport(p=false){
      let reqUrl = this.apiUrl + "/api/get/"+this.modulePage
      if(this.modulPage=='ProjectDataRawListing'){
        reqUrl = "/"+this.projectSlug+(this.pageSlug?"/"+this.pageSlug:"")
      }
      reqUrl += (this.pageSlug?"/"+this.pageSlug:"")
      reqUrl += (this.pageId?"/"+this.pageId:"")
      
      let params = Object.assign({export:1, token: Gen.getCookie("u_auth"+(process.env.VUE_APP_COOKIE_AUTH||""))||""}, this.apiParams)
      params = new URLSearchParams(params).toString()
      
      reqUrl += '?'+params
      if(p) reqUrl += p

      window.open(reqUrl, '_blank')
    },
    doSetAlertForm(resp){
      if(!resp.message) return
      var panel = $(".infoHTML")
      if(resp.alert=="normal"){
        if(["success","warning"].indexOf(resp.status)>-1){
          panel.attr("class","infoHTML alert alert-"+resp.status)
        }else{
          panel.attr("class","infoHTML alert alert-danger")
        }

        panel.html("<div class='alert-body'>"+resp.message+"</div>")

        setTimeout(()=>{
          panel.html("")
          panel.attr("class","infoHTML")
        }, this.duration)
      }else {
        if($.inArray(resp.status,["success","warning"])!=-1){
          return this.$swal({
            title: resp.message,
            icon: resp.status,
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }else{
          return this.$swal({
            title: resp.title || 'Sorry, There is something wrong!',
            text: resp.message || '',
            icon: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      }
    },

    errorMessage(err){
      this.loadingOverlay = false
      let res = err.response
      if(res.status==422) return this.$swal("Sorry, There is something wrong!", _.values(res.data)[0][0])
      this.$swal("Sorry, There is something wrong!", res.data.message, "warning")
    },
    doLogout:function(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah anda akan meninggalkan aplikasi ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, Kembali'
      }).then(result => {
        if (result.value) {
          this.$store.commit("logoutUserToken")
          if(!this.userToken){
            this.$router.push({name:"Login"}).catch(()=>{})
          }
        }
      })
    },
    uploader(filename, def="/assets/images/user-placeholder.svg") {
      def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL +def : def
      if(!filename){
        return def
      }
      return this.apiUrl + '/uploader/' + filename
    },

    configValue(data, value){
      let dt = $.grep((this.Config.mr[data]||[]), attr=>{ return attr.value==value })
      return dt.length?dt[0].label:""
    },
    moduleRole(pRole){
      if(this.$store.getters['canManageAll']) return true
      let role = pRole
      role = typeof role !== Array ? [pRole] : pRole

      const moduleRole = _.filter(this.menuRole().module||[], v=>{
        return role.indexOf(v)>-1
      })

      return !!moduleRole.length
    },
    menuRole(){
      return _.find(this.user.menuRole||[], v=>{
        return v.menu == this.$route.meta?.menu
      })||{}
    },

    deleteItem(index, dataSource) {
      this.$swal({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'You cannot recover deleted data!',
        showCancelButton: true,
        confirmButtonText: 'Ya, hapus',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if (result.value) {
          dataSource.splice(index, 1)
          this.$swal({
            icon: 'success',
            title: 'Data deleted succesfully'
          })
        }
      })
    },
    changeStatus(index, dataSource) {
      let stats = dataSource[index].status == 'Y' ? 'Nonaktif' : 'Aktif'
      this.$swal({
        icon: 'warning',
        title: 'Ubah Status ke '+stats+'?',
        showCancelButton: true,
        confirmButtonText: 'Ya, ubah',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if (result.value) {
          dataSource[index].status = dataSource[index].status == 'Y' ? 'N' : 'Y'
          this.$swal({
            icon: 'success',
            title: 'Status Diubah Menjadi '+stats
          })
        }
      })
    },
    cancelAddUpdate(method) {
      this.$swal({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'Any changes you made will be lost!',
        showCancelButton: true,
        confirmButtonText: 'Yes, go back',
        cancelButtonText: 'No, cancel'
      }).then(result => {
        if (result.value) {
          method ? method() : this.$router.push({ name: this.$route.name }).catch(()=>{})
        }
      })
    },
    confirmChanges(type, obj) {
      const objName = (obj ? obj : 'Data') + ' ' + (type == 'update' ? 'Updated' : 'Added')
      this.$swal({
        icon: 'success',
        title: objName+' Successfully.'
      })
    },
    getQuartal(month) {
      if(month == 0 || month == 1 || month == 2){
        return {
          sQ : 0,
          eQ : 2
        }
      }
      else if(month == 3 || month == 4 || month == 5){
        return {
          sQ : 3,
          eQ : 5
        }
      }
      else if(month == 6 || month == 7 || month == 8){
        return {
          sQ : 6,
          eQ : 8
        }
      }
      else{
        return {
          sQ : 9,
          eQ : 11
        }
      }
    },
    removeWildChar(e){
      let regex = /^[^[\]{}*!?^%#\/^/\\#?]*$/
      if (!regex.test(e.target.value)) {
        return e.target.value = e.target.value.slice(0, -1)
      }
    },
    
    // Formatter
    removeDoubleSpace(e){
      return String(e).replace(/  +/g, ' ')
    },
    normalText(e){
      return String(e).replace(/[^a-zA-Z0-9'.\s-/,_()&"{}|?<>\[\]]:;/g, "").replace(/  +/g, ' ')
    },
    text(e){      
      return String(e).replace(/[^a-zA-Z'.\s-_]/g, "").replace(/  +/g, ' ')
    },
    remarks(e){
      return String(e).replace(/[^a-zA-Z'.\s-,!"'_]/g, "").replace(/  +/g, ' ')
    },
    username(e){
      return String(e).replace(/[^a-zA-Z'.\s-_]/g, "").replace(/  +/g, ' ').trim()
    },
    password(e){
      return String(e).replace(/[^a-zA-Z'.\s-_]/g, "").trim()
    },
    numberOnly(e){
      return String(e).replace(/[^0-9]/g, "")
    },
    number(e){
      let words = String(e).replace(/[^0-9.,]/g, "")
      return words.replace(/,/g, ".")
    },
    code(e){
      return String(e).replace(/[^a-zA-Z0-9.-]/g, "")
    },
    companyName(e){      
      return String(e).replace(/[[\]{}*!?^%#\/^/\\`#?]*$/, "").replace("PT", "").replace("CV", "").replace("Lainnya", "").trimStart()
    },
    year(e){
      return String(e).replace(/[^0-9]/g, "").substring(0, 4)
    },
    nik(e) {
      return String(e).replace(/[^0-9]/g, "").substring(0, 16)
    },
    nib(e){
      return String(e).replace(/[^0-9]/g, "").substring(0, 13)
    },
    telp(e){
      return String(e).replace(/[^0-9]/g, "").replace(/[+,\-,\s]/g, '').substring(0, 18)
    },
    formatRupiah(value) {      
      return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(value || 0)
    },
    formatThousand(value){
      return new Intl.NumberFormat('de-DE').format(value)
    },
    formatPercent(value){
      return `${value}%`
    },
    formatLink(){
      return /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
    },
    
    length(arr){
      return arr?.length
    },
    capitalize(e){
      const words = String(e).split(" ")

      return words.map(word => { 
        if(word[0]) return word[0].toUpperCase() + word.substring(1)        
      }).join(" ").replace(/[^a-zA-Z'.\s-_]/g, "").replace(/  +/g, ' ')
    },
    uppercase(e){
      const words = String(e)
      return words.toUpperCase().replace(/[^a-zA-Z\s]/g, "").replace(/  +/g, ' ')
    },
    uppercaseWord(e=""){
      if(e){
        const words = String(e)
        return words.toUpperCase()
      }else{
        return null
      }
    }
  },
}
</script>