const Config = {
  app:{
    webaname:'RSH Hospital',
    company:'RSH Hospital',
    copyright:()=>{
      let date = new Date()
      if(date.getFullYear()>2022){
        return '2022-'+date.getFullYear()
      }else{
        return '2022'
      }
    }
  },

  encrypt_key: "#@PI2022!!",
  datePickerOnlyDate: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
  mr:{
    StatusOptions: [
      { text: 'Aktif', value: 'Y' },
      { text: 'Nonaktif', value: 'N' },
    ],
    StatusMenular: [
      { text: 'Menular', value: 'Y' },
      { text: 'Tidak Menular', value: 'N' },
    ],    
    StatusPanggil: [
      { text: 'Hadir', value: 'Y' },
      { text: 'Belum Hadir', value: 'B' },
      { text: 'Tidak Hadir', value: 'N' },
    ],
    StatusRegular: [
      { text: 'Regular', value: 'R' },
      { text: 'Iregular', value: 'I' },
    ],
    StatusPernikahan: [
      { text: 'Belum Kawin', value: 1 },
      { text: 'Kawin', value: 2 },
      { text: 'Cerai Hidup', value: 3 },
      { text: 'Cerai Mati', value: 4 },
    ],
    StatusKelamin: [
      {
        text: 'LAKI-LAKI',
        value: 1
      }, {
        text: 'PEREMPUAN',
        value: 2
      }, {
        text: 'TIDAK DIKETAHUI',
        value: "0"
      }, {
        text: 'TIDAK DAPAT DITENTUKAN',
        value: 3
      }, {
        text: 'TIDAK MENGISI',
        value: 4
      },
    ],
    
    
    KategoriRadiologi: [{
        text: 'Radiologi Tanpa Kontras',
        value: 1
      },
      {
        text: 'Ultrasonografi (USG)',
        value: 2
      },
      {
        text: 'Radiologi dengan Kontras',
        value: 3
      },
      {
        text: 'Pemeriksaan CT-SCAN',
        value: 4
      }
    ],
    KategoriLab: [{
        text: 'Hematologi',
        value: 'Hematologi'
      },
      {
        text: 'Kimia',
        value: 'Kimia'
      },
      {
        text: 'Imuno Serologi',
        value: 'Imuno Serologi'
      },
      {
        text: 'Urinalisis',
        value: 'Urinalisis'
      },
      {
        text: 'Analisa Faeces',
        value: 'Analisa Faeces'
      },
      {
        text: 'Endokrinologi',
        value: 'Endokrinologi'
      },
      {
        text: 'Osteoporosis',
        value: 'Osteoporosis'
      },
      {
        text: 'Lain-lain',
        value: 'Lain-lain'
      },
    ],
    gender: [
      { text: 'Laki-Laki', value: 'P' },
      { text: 'Perempuan', value: 'W' },
    ],
    yesNoOpt: [
      { text: 'Ya', value: 'Y' },
      { text: 'Tidak Ada', value: 'N' },
    ],
    yesNoOptV2: [
      { text: 'Ya', value: 'Y' },
      { text: 'Tidak', value: 'N' },
    ],
    yesNoOptV3: [
      { text: 'Ya', value: 1 },
      { text: 'Tidak', value: 0 },
    ],
    golDarah: [
      { text: 'A', value: 'A' },
      { text: 'B', value: 'B' },
      { text: 'O', value: 'O' },
      { text: 'AB', value: 'AB' }
    ],
    baikNoOpt: [
      { text: 'Baik', value: 'B' },
      { text: 'Tidak Baik', value: 'TB' },
    ],
    psikologiPot: [
      { 
        text: 'Tidak ada kelainan', 
        value: 1 
      },
      { 
        text: 'Cemas', 
        value: 2 
      },
      {
        text: 'Takut',
        value: 3
      },
      {
        text: 'Marah',
        value: 4
      },
      {
        text: 'Sedih',
        value: 5
      },
      {
        text: 'Lain-lain',
        value: 6
      },
    ],
    adlStatus: [
      {
        text: 'Mandiri',
        value: 'MANDIRI'
      },
      {
        text: 'Dibantu',
        value: 'DIBANTU'
      },
    ],
    skalaNyeri: [
      {
        text: '0',
        value: 0,
        class: 'psr-blue',
      },
      {
        text: '1',
        value: 1,
        class: 'psr-green',
      },
      {
        text: '2',
        value: 2,
        class: 'psr-green',
      },
      {
        text: '3',
        value: 3,
        class: 'psr-light-green',
      },
      {
        text: '4',
        value: 4,
        class: 'psr-light-green',
      },
      {
        text: '5',
        value: 5,
        class: 'psr-yellow',
      },
      {
        text: '6',
        value: 6,
        class: 'psr-yellow',
      },
      {
        text: '7',
        value: 7,
        class: 'psr-orange',
      },
      {
        text: '8',
        value: 8,
        class: 'psr-orange',
      },
      {
        text: '9',
        value: 9,
        class: 'psr-red',
      },
      {
        text: '10',
        value: 10,
        class: 'psr-red',
      },
    ],
    diagnosaKhusus: [
      {
        text: 'Tidak Ada',
        value: 'Tidak Ada'
      },
      {
        text: 'Diabetes Mellitus',
        value: 'Diabetes Mellitus'
      },
      {
        text: 'Hipertensi',
        value: 'Hipertensi'
      },
      {
        text: 'Cronical Renal Failure',
        value: 'Cronical Renal Failure'
      },
      {
        text: 'Infeksi Kronis',
        value: 'Infeksi Kronis'
      },
      {
        text: 'Malnutrisi',
        value: 'Malnutrisi'
      },
      {
        text: 'Obesitas',
        value: 'Obesitas'
      },
      {
        text: 'Lschemic Heart Disease',
        value: 'Lschemic Heart Disease'
      },
      {
        text: 'Cences',
        value: 'Cences'
      },
      {
        text: 'Lainnya',
        value: 'L'
      },
    ],
    kriteriaHasil: [
      {
        text: '1',
        value: 1
      },
      {
        text: '2',
        value: 2
      },
      {
        text: '3',
        value: 3
      },
      {
        text: '4',
        value: 4
      },
      {
        text: '5',
        value: 5
      }
    ],
    configStatus: [
      {
        text: 'SEMUA STATUS',
        value: 'ALL'
      },
      {
        text: 'DALAM ANTRIAN',
        value: 'QUEUE'
      },
      {
        text: 'TIDAK HADIR',
        value: 'ABSEN'
      },
      {
        text: 'DALAM TINDAKAN',
        value: 'ACTION'
      },
      {
        text: 'VOID',
        value: 'VOID'
      },
      {
        text: 'SELESAI',
        value: 'DONE'
      }
    ],
    configStatusTidakHadir: [{
        text: 'SEMUA STATUS',
        value: 'ALL'
      },
      {
        text: 'TIDAK HADIR',
        value: 'ABSEN'
      },
      {
        text: 'VOID',
        value: 'VOID'
      }
    ],
    configStatusFarmasi: [
      {
        text: 'ALL',
        value: 'ALL'
      },
      {
        text: 'PENDING',
        value: 'N'
      },
      {
        text: 'SELESAI',
        value: 'Y'
      }
    ],
    configStatusLokalis: [
      {
        text: 'Anatomi Badan',
        value: 'body',
        image: 'img/anatomy-male.png',
        image2: 'img/anatomy-female.png',
      },
      {
        text: 'Anatomi Mata',
        value: 'eye',
        image: 'img/anatomy-collection/eye-anatomy.png',
        image2: 'img/anatomy-collection/eye-anatomy.png',
      },
      {
        text: 'Anatomi Mata Depan',
        value: 'front-eye',
        image: 'img/anatomy-collection/front-eye-anatomy.png',
        image2: 'img/anatomy-collection/front-eye-anatomy.png',
      },
      {
        text: 'Odontogram',
        value: 'odontogram',
        image: 'img/anatomy-collection/odontogram.png',
        image2: 'img/anatomy-collection/odontogram.png',
      },
      {
        text: 'Anatomi Syaraf',
        value: 'nervousSystem',
        image: 'img/anatomy-collection/nervous-anatomy.png',
        image2: 'img/anatomy-collection/nervous-anatomy.png',
      },
      {
        text: 'Anatomi Jantung',
        value: 'heart',
        image: 'img/anatomy-collection/heart-anatomy.png',
        image2: 'img/anatomy-collection/heart-anatomy.png',
      },
      {
        text: 'Anatomi Telinga',
        value: 'ear',
        image: 'img/anatomy-collection/ear-anatomy.png',
        image2: 'img/anatomy-collection/ear-anatomy.png',
      },
      {
        text: 'Anatomi Hidung',
        value: 'nose',
        image: 'img/anatomy-collection/nose-anatomy.png',
        image2: 'img/anatomy-collection/nose-anatomy.png',
      },
      {
        text: 'Anatomi Tenggorokan',
        value: 'throat',
        image: 'img/anatomy-collection/pharynx-anatomy.png',
        image2: 'img/anatomy-collection/pharynx-anatomy.png',
      },
      {
        text: 'Anatomi Paru',
        value: 'lung',
        image: 'img/anatomy-collection/lung-anatomy.png',
        image2: 'img/anatomy-collection/lung-anatomy.png',
      },
      {
        text: 'Anatomi Sendi',
        value: 'joint',
        image: 'img/anatomy-collection/joint-anatomy.png',
        image2: 'img/anatomy-collection/joint-anatomy.png',
      },
    ],
    isMovePoli: [
      {
        text: 'Ya, Pindah Poli',
        value: 'Y'
      },
      {
        text: 'Tidak',
        value: 'N'
      },
    ],
    mKesadaran: [
      {
        text: 'Composmentris (GCS 14 - 15)',
        value: 1,
      },
      {
        text: 'Apatis (GCS 12 - 13)',
        value: 2,
      },
      {
        text: 'Somenolen (GCS 10 - 11)',
        value: 3,
      },
      {
        text: 'Delirium (GCS 7 - 9)',
        value: 4,
      },
      {
        text: 'Soporo (GCS 4 - 6)',
        value: 5,
      },
      {
        text: 'Coma (GCS 1 - 3)',
        value: 6,
      },
    ],
    keselamatanPasienA: [{
        text: 'Tidak seimbang/sempoyongan/limbung',
        value: 'Tidak seimbang/sempoyongan/limbung'
      },
      {
        text: 'Jalan dengan menggunakan alat bantu (kruk, tripot, kursi roda, orang lain)',
        value: 'Jalan dengan menggunakan alat bantu (kruk, tripot, kursi roda, orang lain)'
      },
    ],
    keselamatanPasienB: [{
        text: 'Menopang saat akan duduk : tampak memegang pinggiran kursi atau meja ata benda lain sebagai penopang saat akan duduk',
        value: 'Menopang saat akan duduk : tampak memegang pinggiran kursi atau meja ata benda lain sebagai penopang saat akan duduk'
      }
    ],

    jenisInsiden: [
      {
        text: 'KNC',
        value: 1
      },
      {
        text: 'KTD',
        value: 2
      },
      {
        text: 'KTC',
        value: 3
      },
      {
        text: 'KPC',
        value: 4
      },
      {
        text: 'Sentinel',
        value: 5
      },
    ],
    mReportInsiden: [{
        text: 'Karyawan',
        value: 'Karyawan'
      },
      {
        text: 'Pasien',
        value: 'Pasien'
      },
      {
        text: 'Keluarga / Pendamping pasien',
        value: 'Keluarga / Pendamping pasien'
      },
      {
        text: 'Pengunjung',
        value: 'Pengunjung'
      },
      {
        text: 'Lain-Lain',
        value: 'L'
      },
    ],
    mOccure: [{
        text: 'Pasien',
        value: 'Pasien'
      },
      {
        text: 'Lain-Lain',
        value: 'L'
      },
    ],
    insidenPasien: [
      {
        text: 'Pasien Rawat Inap',
        value: 'Pasien Rawat Inap'
      },
      {
        text: 'Pasien Rawat Jalan',
        value: 'Pasien Rawat Jalan'
      },
      {
        text: 'Pasien UGD',
        value: 'Pasien UGD'
      },
      {
        text: 'Lain-lain',
        value: 'L'
      },
    ],
    akibatInsidenPadaPasien: [
      {
        text: 'Kematian',
        value: 'Kematian'
      }, {
        text: 'Cedera Irreversibel / Cedera Berat',
        value: 'Cedera Irreversibel / Cedera Berat'
      }, {
        text: 'Cedera Reversibel / Cedera Sedang',
        value: 'Cedera Reversibel / Cedera Sedang'
      }, {
        text: 'Cedera Ringan',
        value: 'Cedera Ringan'
      }, {
        text: 'Tidak ada cedera',
        value: 'Tidak ada cedera'
      }
    ],

    tindakanOleh: [{
      text: 'Tim',
      value: 'Tim'
    }, {
      text: 'Dokter',
      value: 'Dokter'
    }, {
      text: 'Perawat',
      value: 'Perawat'
    }, {
      text: 'Petugas Lainnya',
      value: 'Petugas Lainnya'
    }],

    probabilitasOpt: [{
      text: 'Sangat Jarang (>5 tahun/kali)',
      value: 'Sangat Jarang (>5 tahun/kali)'
    }, {
      text: 'Jarang (>2-5 tahun/kali)',
      value: 'Jarang (>2-5 tahun/kali)'
    }, {
      text: 'Mungkin (1-2 tahun/ kali)',
      value: 'Mungkin (1-2 tahun/ kali)'
    }, {
      text: 'Sering (beberapa kali/tahun)',
      value: 'Sering (beberapa kali/tahun)'
    }, {
      text: 'Sangat sering (tiap minggu/ bulan)',
      value: 'Sangat sering (tiap minggu/ bulan)'
    }
   ],

   gradingOpt: [{
     text: 'BIRU',
     value: 'BIRU'
   }, {
     text: 'HIJAU',
     value: 'HIJAU'
   }, {
     text: 'KUNING',
     value: 'KUNING'
   }, {
     text: 'MERAH',
     value: 'MERAH'
   }],

  configResep: [
    {
      text: 'Obat',
      value: 1
    },
    {
      text: 'Alat Kesehatan',
      value: 2
    },
    {
      text: 'Racikan',
      value: 3
    },
  ],

  configStatusRM: [
    {
      text: 'Semua Status',
      value: 'ALL'
    },{
      text: 'Verifikasi Rekam Medis',
      value: 6
    }, {
      text: 'Selesai',
      value: 7
    },
  ],

  configTindakLanjut: [
    {
      text: 'Edukasi',
      value: 'Edukasi'
    },
    {
      text: 'Kontrol',
      value: 'Kontrol'
    },
    {
      text: 'Rawat Inap',
      value: 'Rawat Inap'
    },
  ],

  configPrioritasPemeriksaan: [
    {
      text: 'CITO',
      value: 1
    },
    {
      text: 'Non-CITO',
      value: 2
    },
  ],
  
  configAnamnesa: [
    {
      text: 'Pasien Sendiri',
      value: 1
    },
    {
      text: 'Orang Lain',
      value: 2
    },
  ],
  
  configNilaiNyeri: [
    {
      text: 'Tidak',
      value: 1
    },
    {
      text: 'Ya',
      value: 2
    },
    {
      text: 'Sulit Dinilai',
      value: 3
    },
  ],

  configLamaNyeri: [
    {
      text: '> 3 bulan(Kronik)',
      value: 1
    },
    {
      text: '< 3 bulan (Akut)',
      value: 2
    },
    {
      text: 'Sulit Dinilai',
      value: 3
    },
  ],

  
  statusGizi: [
    {
      text: 'Gizi Kurang/Buruk',
      value: 'Gizi Kurang/Buruk'
    },
    {
      text: 'Gizi Cukup',
      value: 'Gizi Cukup'
    },
    {
      text: 'Gizi Lebih',
      value: 'Gizi Lebih'
    },
    {
      text: 'Lainnya',
      value: 'L'
    },
  ],
  
  filterTypeLaporan: [
    {
      text: 'SEMUA KATEGORI',
      value: 'ALL'
    },
    {
      text: 'REKAM MEDIS',
      value: 'RM'
    },
    {
      text: 'SPLPA',
      value: 'SPLPA'
    },
    {
      text: 'LAINNYA',
      value: 'L'
    },
  ],

  statusNyeri: [
    {
      text: 'Tidak ada Nyeri',
      value: 'Tidak ada Nyeri'
    },
    {
      text: 'Tajam',
      value: 'Tajam'
    },
    {
      text: 'Seperti ditarik',
      value: 'Seperti ditarik'
    },
    {
      text: 'Seperti ditusuk',
      value: 'Seperti ditusuk'
    },
    {
      text: 'Seperti dibakar',
      value: 'Seperti dibakar'
    },
    {
      text: 'Seperti ditikam',
      value: 'Seperti ditikam'
    },
    {
      text: 'Nyeri tumpul',
      value: 'Nyeri tumpul'
    },
    {
      text: 'Seperti dipukul',
      value: 'Seperti dipukul'
    },
    {
      text: 'Seperti berdenyut',
      value: 'Seperti berdenyut'
    },
    {
      text: 'Seperti kram',
      value: 'Seperti kram'
    },
    {
      text: 'Sulit Dinilai',
      value: 'Sulit Dinilai'
    },
  ],

  kompres:[
    {
      text: 'Hangat',
      value: 'Hangat'
    },
    {
      text: 'Dingin',
      value: 'Dingin'
    },
  ],
  
  aktifitas:[
    {
      text: 'Dikurangi',
      value: 'Dikurangi'
    },
    {
      text: 'Ditambah',
      value: 'Ditambah'
    },
  ],
  
  statusDirujuk: [
    {
      text: 'Klinik Gizi',
      value: 'Klinik Gizi'
    },
    {
      text: 'Tim Nyeri',
      value: 'Tim Nyeri'
    },
    {
      text: 'Edukasi',
      value: 'Edukasi'
    },
    {
      text: 'Lainnya',
      value: 'L'
    },
  ],
  
  statusElektroterapi: [
    {
      text: 'Short Wave Diaterm / UKG',
      value: 'Short Wave Diaterm / UKG'
    },
    {
      text: 'Stimulasi (Faradik/ Galvanik)',
      value: 'Stimulasi (Faradik/ Galvanik)'
    },
    {
      text: 'Ultra Sonik',
      value: 'Ultra Sonik'
    }
  ],
  
  statusMekanoTerapi: [
    {
      text: 'Traksi Cervical / Lumbal',
      value: 'Traksi Cervical / Lumbal'
    },
    {
      text: 'Massage',
      value: 'Massage'
    },
    {
      text: 'Exercise Terapi',
      value: 'Exercise Terapi'
    }
  ],

  statusLatihan: [
    {
      text: 'Penguatan Otot',
      value: 'Penguatan Otot'
    },
    {
      text: 'Koordinasi Gerak',
      value: 'Koordinasi Gerak'
    },
    {
      text: 'Breathing Exersice',
      value: 'Breathing Exersice'
    },
    {
      text: 'Pergerakan Sendi',
      value: 'Pergerakan Sendi'
    },
    {
      text: 'Latihan Jalan',
      value: 'Latihan Jalan'
    },
    {
      text: 'Latihan pra dan pasca bedah',
      value: 'Latihan pra dan pasca bedah'
    },
  ],
  kategoriAsupan: [
    {
      text: 'Kurang',
      value: 'Kurang'
    },
    {
      text: 'Baik',
      value: 'Baik'
    },
    {
      text: 'Lebih',
      value: 'Lebih'
    }
  ],
  kategoriTindakan: [{
      text: 'Elektroterapi',
      value: 'E'
    },
    {
      text: 'Mekanoterapi',
      value: 'M'
    },
    {
      text: 'Latihan-latihan',
      value: 'L'
    }
  ],

  rencanaTindakan: [{
      text: 'Diathermi',
      value: 'Diathermi'
    },
    {
      text: 'Stimulasi (Tens)',
      value: 'Stimulasi (Tens)'
    },
    {
      text: 'Traksi Lumbal (TL)',
      value: 'Traksi Lumbal (TL)'
    },
    {
      text: 'UltraSound',
      value: 'UltraSound'
    },
    {
      text: 'Infra Red (IR)',
      value: 'Infra Red (IR)'
    },
    {
      text: 'Traksi Cervical (TC)',
      value: 'Traksi Cervical (TC)'
    },
    {
      text: 'Exercise',
      value: 'Exercise'
    },
  ],
  typeSatuan: [{
      text: 'BUNGKUS',
      value: 'BUNGKUS'
    },
    {
      text: 'KAPSUL',
      value: 'KAPSUL'
    },
    {
      text: 'BOTOL',
      value: 'BOTOL'
    },
    {
      text: 'TUBE',
      value: 'TUBE'
    },
    {
      text: 'POT',
      value: 'POT'
    },
  ],
  rentangUmur: [{
      text: 'SEMUA RENTANG UMUR',
      value: 'ALL'
    },
    {
      text: 'BAYI',
      value: 'BAYI'
    },
    {
      text: 'ANAK-ANAK',
      value: 'ANAK-ANAK'
    },
    {
      text: 'DEWASA',
      value: 'DEWASA'
    },
    {
      text: 'LANSIA',
      value: 'LANSIA'
    },
  ],
  jenisKelamin: [{
      text: 'SEMUA JENIS KELAMIN',
      value: 'ALL'
    },
    {
      text: 'LAKI-LAKI',
      value: 1
    },
    {
      text: 'PEREMPUAN',
      value: 2
    },
    {
      text: 'TIDAK DIKETAHUI',
      value: 0
    },
    {
      text: 'TIDAK DAPAT DITENTUKAN',
      value: 3
    },
    {
      text: 'TIDAK MENGISI',
      value: 4
    },
  ],
  statusPasien: [{
      text: 'SEMUA STATUS PASIEN',
      value: 'ALL'
    },
    {
      text: 'PASIEN LAMA',
      value: 'Y'
    },
    {
      text: 'PASIEN BARU',
      value: 'N'
    },
  ],
  isPerawatOrDokter: [
    {
      text: 'Perawat',
      value: 'Perawat'
    },{
      text: 'Dokter',
      value: 'Dokter'
    }
  ],
  configVitalSign: [{
      'value': 'apod_vs_kepala',
      'label': 'Kepala',
      'isOpen': false
    },
    {
      'value': 'apod_vs_mata',
      'label': 'Mata',
      'isOpen': false
    },
    {
      'value': 'apod_vs_hidung',
      'label': 'Hidung',
      'isOpen': false
    },
    {
      'value': 'apod_vs_rambut',
      'label': 'Rambut',
      'isOpen': false
    },
    {
      'value': 'apod_vs_bibir',
      'label': 'Bibir',
      'isOpen': false
    },
    {
      'value': 'apod_vs_gigi',
      'label': 'Gigi',
      'isOpen': false
    },
    {
      'value': 'apod_vs_lidah',
      'label': 'Lidah',
      'isOpen': false
    },
    {
      'value': 'apod_vs_langit',
      'label': 'Langit',
      'isOpen': false
    },
    {
      'value': 'apod_vs_leher',
      'label': 'Leher',
      'isOpen': false
    },
    {
      'value': 'apod_vs_tenggorokan',
      'label': 'Tenggorokan',
      'isOpen': false
    },
    {
      'value': 'apod_vs_tonsil',
      'label': 'Tonsil',
      'isOpen': false
    },
    {
      'value': 'apod_vs_dada',
      'label': 'Dada',
      'isOpen': false
    },
    {
      'value': 'apod_vs_payudara',
      'label': 'Payudara',
      'isOpen': false
    },
    {
      'value': 'apod_vs_punggung',
      'label': 'Punggung',
      'isOpen': false
    },
    {
      'value': 'apod_vs_perut',
      'label': 'Perut',
      'isOpen': false
    },
    {
      'value': 'apod_vs_genital',
      'label': 'Genital',
      'isOpen': false
    },
    {
      'value': 'apod_vs_anus',
      'label': 'Anus',
      'isOpen': false
    },
    {
      'value': 'apod_vs_lengan_atas',
      'label': 'Lengan Atas',
      'isOpen': false
    },
    {
      'value': 'apod_vs_lengan_bawah',
      'label': 'Lengan Bawah',
      'isOpen': false
    },
    {
      'value': 'apod_vs_jari_tangan',
      'label': 'Jari Tangan',
      'isOpen': false
    },
    {
      'value': 'apod_vs_kuku',
      'label': 'Kuku',
      'isOpen': false
    },
    {
      'value': 'apod_vs_sendi_tangan',
      'label': 'Sendi Tangan',
      'isOpen': false
    },
    {
      'value': 'apod_vs_tungkai_atas',
      'label': 'Tungkai Atas',
      'isOpen': false
    },
    {
      'value': 'apod_vs_tungkai_bawah',
      'label': 'Tungkai Bawah',
      'isOpen': false
    },
    {
      'value': 'apod_vs_jari_kaki',
      'label': 'Jari Kaki',
      'isOpen': false
    },
    {
      'value': 'apod_vs_kuku_kaki',
      'label': 'Kuku Kaki',
      'isOpen': false
    },
    {
      'value': 'apod_vs_persendian_kaki',
      'label': 'Persendian Kaki',
      'isOpen': false
    }
  ],
  jenisAlergi: [{
    text: 'Obat',
    value: 'Obat'
  },
  {
    text: 'Makanan',
    value: 'Makanan'
  },
  {
    text: 'Udara',
    value: 'Udara'
  },
  {
    text: 'Lainnya',
    value: 'Lainnya'
  },
  ],


  // baru
  statusKewarganegaraan: [{
      text: 'WNA',
      value: 'WNA'
    },
    {
      text: 'WNI',
      value: 'WNI'
    },
  ],
  statusIzin: [{
      text: 'Mengijinkan',
      value: 'Y'
    },
  ],
  statusPemeriksaan: [{
      text: 'Semua Pemeriksaan',
      value: 'ALL'
    },
    {
      text: 'Poliklinik',
      value: 'Poli'
    },
    {
      text: 'Penunjang/Lab',
      value: 'Penunjang'
    },
  ],
  statusRujukan: [
    {
      text: 'Menunggu',
      value: 'Q'
    },
    {
      text: 'Selesai',
      value: 'D'
    }
  ],
  tipeRujukan: [{
      text: 'Rujuk Internal',
      value: 'RI'
    },
    {
      text: 'Rujuk Kasus',
      value: 'RK'
    },
    {
      text: 'Rujuk Balik',
      value: 'RB'
    }
  ],
  statusJaminan: [{
      text: 'Semua Cara Bayar',
      value: 'ALL'
    },
    {
      text: 'Umum/Pribadi',
      value: 1
    },
    {
      text: 'Asuransi/Perusahaan',
      value: 2
    },
    {
      text: 'BPJS Kesehatan',
      value: 3
    },
  ],
  mJaminan: [
    {
      text: 'Umum/Pribadi',
      value: 1
    },
    {
      text: 'Asuransi/Perusahaan',
      value: 2
    },
    {
      text: 'BPJS Kesehatan',
      value: 3
    },
  ],
  statusPemeriksaanOpt: [
    {
      text: 'Poliklinik',
      value: 'Poli'
    },
    {
      text: 'Penunjang/Lab',
      value: 'Penunjang'
    },
  ],
  statusPenunjangOpt: [{
      text: 'Laboratorium',
      value: 'LAB'
    },
    {
      text: 'Radiologi',
      value: 'RADIO'
    },
    {
      text: 'Fisioterapi',
      value: 'FISIO'
    },
  ],
  mFastTrack: [
    {
      text: 'Menggunakan Alat Bantu',
      value: 'Y'
    },
    {
      text: 'Lainnya',
      value: 'N'
    },
  ],
  mTypeJaminan: [
    {text: "BPJS Kesehatan", value: "B"},
    {text: "Umum", value: "U"}, 
  ],
  mPelayanan: [{
      text: "Rawat Inap",
      value: "RI"
    },
    {
      text: "Rawat Jalan",
      value: "RJ"
    },
  ],
  mRujukan: [{
      text: "Penuh",
      value: "F"
    },
    {
      text: "Partial",
      value: "P"
    },
    {
      text: "Rujuk Balik(Non PRBI)",
      value: "B"
    },
  ],
  mUnitCategory: [
    {
      text: "Admisi",
      value: "admisi"
    },
    {
      text: "Loket",
      value: "loket"
    },
    {
      text: "Penunjang",
      value: "penunjang"
    },
    {
      text: "Poli",
      value: "poli"
    },
    {
      text: "Farmasi",
      value: "farmasi"
    },
  ],
  typeRujukan: [
    {
      text: "Rujuk Internal",
      value: "RI"
    }, {
      text: "Rujuk Kasus",
      value: "RK"
    }, {
      text: "Rujuk Balik",
      value: "RB"
    },
  ],
  typeRujukanV2: [
    {
      text: "Rujuk Internal",
      value: "RI"
    }
  ],
  alasanRujuk: [{
      text: "Klinis",
      value: "Klinis"
    }, {
      text: "Non Klinis",
      value: "Non Klinis"
    }, {
      text: "Lain-lain",
      value: "Lain-lain"
    },
  ],
  mediaTerpasang: [
    {
      text: "Infus",
      value: "Infus"
    }, {
      text: "NGT",
      value: "NGT"
    }, {
      text: "DC",
      value: "DC"
    }, {
      text: "Oksigen",
      value: "Oksigen"
    },
  ],

  jenisFaskes: [{
    text: "Faskes",
    value: 1
  }, {
    text: "Faskes/RS",
    value: 2
  }],

  jenisPelayanan: [{
    text: "Rawat Inap",
    value: 1
  }, {
    text: "Rawat Jalan",
    value: 2
  }],

  statusKlaim: [{
    text: "Proses Verifikasi",
    value: 1
  }, {
    text: "Pending Verifikasi",
    value: 2
  }, {
    text: "Klaim",
    value: 3
  }],

  typeRujukanRS: [{
    text: "PCare",
    value: 1
  }, {
    text: "Rumah Sakit",
    value: 2
  }],

  jenisNOKA: [{
    text: "NIK",
    value: "nik"
  }, {
    text: "Nomor Kartu",
    value: "noka"
  }],

  jenisFormat: [{
    text: "Tanggal Entri",
    value: 1
  }, {
    text: "Tanggal R. Kontrol",
    value: 2
  }],

  jenisKontrol: [{
    text: "SPRI (Nomor Kartu)",
    value: 1
  }, {
    text: "Rencana Kontrol (Nomor SEP)",
    value: 2
  }],

  configLakaLantas: [
    {
      value: "0",
      text: "Bukan Kecelakaan lalu lintas [BKLL]"
    },
    {
      value: 1,
      text: "KLL dan bukan kecelakaan Kerja [BKK]"
    },
    {
      value: 2,
      text: "KLL dan KK"
    },
    {
      value: 3,
      text: "KK"
    },
  ],

  configTujuan: [{
      value: "0",
      text: "Normal"
    },
    {
      value: "1",
      text: "Prosedur"
    },
    {
      value: "2",
      text: "Konsul Dokter"
    }
  ],
  
  configPenunjang: [
    {
      value : 1,
      text: "Radioterapi"
    },
    
    {
      value : 2,
      text: "Kemoterapi"
    },
    
    {
      value : 3,
      text: "Rehabilitasi Medik"
    },
    
    {
      value : 4,
      text: "Rehabilitasi Psikososial"
    },
    
    {
      value : 5,
      text: "Transfusi Darah"
    },
    
    {
      value : 6,
      text: "Pelayanan Gigi"
    },
    
    {
      value : 7,
      text: "Laboratorium"
    },
    
    {
      value : 8,
      text: "USG"
    },
    
    {
      value : 9,
      text: "Farmasi"
    },
    
    {
      value : 10,
      text: "Lain - Lain"
    },
    
    {
      value : 11,
      text: "MRI"
    },
    
    {
      value : 12,
      text: "HEMODIALISA"
    },
  ],
  configFlag: [
    {
      value: 0,
      text: "Prosedur Tidak Berkelanjutan"
    },

    {
      value: 1,
      text: "Prosedur dan Terapi Berkelanjutan"
    },
  ],
  kelasRawat: [{
      value: 1,
      text: "Kelas 1"
    },
    {
      value: 2,
      text: "Kelas 2"
    },
    {
      value: 3,
      text: "Kelas 3"
    },
  ],

  },
  wording:{
    detectUpdate: 'Perubahan data belum di simpan.'
  },
  toolsBasicCK: [
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'link',
    'bulletedList',
    'numberedList',
    '|',
    'undo',
    'redo'
  ],
  dataShownOptions: [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '30', value: 30 },
  ],
  dataShown:25
}
export default Config