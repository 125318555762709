const bo_routes = [
  {
    path: '',
    redirect: { name: 'Login' },
  },
  {
    path: 'login',
    name: 'Login',
    component: () => import('@/views/backend/Login.vue'),
    meta: {
      redirectIfLoggedIn: true,
      layout: 'empty',
    }
  },

  // pendaftaran online
  {
    path: 'display-poli',
    name: 'DisplayPoli',
    component: () => import('@/views/display/DisplayPoli.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-penunjang',
    name: 'DisplayLab',
    component: () => import('@/views/display/DisplayLab.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian',
    name: 'DisplayAntrian',
    component: () => import('@/views/display/DisplayAntrian.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-checkin',
    name: 'DisplayCheckin',
    component: () => import('@/views/display/DisplayCheckin.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-finger-print',
    name: 'DisplayFingerPrint',
    component: () => import('@/views/display/DisplayFingerPrint.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian-admisi',
    name: 'DisplayAntrianAdmisi',
    component: () => import('@/views/display/DisplayAntrianAdmisi.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian-farmasi',
    name: 'DisplayAntrianFarmasi',
    component: () => import('@/views/display/DisplayAntrianFarmasi.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-check-in',
    name: 'DisplayCheckin',
    component: () => import('@/views/display/DisplayCheckin.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'input-kode-booking',
    name: 'InputKodeBooking',
    component: () => import('@/views/display/InputKodeBooking.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'scan-qr-code',
    name: 'ScanQRCode',
    component: () => import('@/views/display/ScanQRCode.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'done-checkin/:pageSlug?',
    name: 'DoneCheckin',
    component: () => import('@/views/display/DoneCheckin.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'input-kode-bpjs',
    name: 'InputKodeBPJS',
    component: () => import('@/views/display/InputKodeBPJS.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  // pendaftaran online
  

  {
    path: 'dashboard/:pageSlug?',
    name: 'Dashboard',
    component: () => import('@/views/backend/Dashboard/Dashboard.vue'),
    meta: {
      menu: 'Dashboard',
      pageTitle: 'Dashboard',
      objName: 'Dashboard',
    },
  },
  {
    path: 'hais/:pageSlug?',
    name: 'Hais',
    component: () => import('@/views/backend/Hais/List.vue'),
    meta: {
      menu: 'Hais',
      pageTitle: 'Hais',
      objName: 'Hais',
    },
  },
  {
    path: 'keselamatan-pasien/:pageSlug?',
    name: 'KeselamatanPasien',
    component: () => import('@/views/backend/KeselamatanPasien/List.vue'),
    meta: {
      menu: 'KeselamatanPasien',
      pageTitle: 'Resiko Jatuh',
      objName: 'Resiko Jatuh',
    },
  },
  {
    path: 'laporan-insiden/:pageSlug?',
    name: 'LaporanInsiden',
    component: () => import('@/views/backend/LaporanInsiden/List.vue'),
    meta: {
      menu: 'LaporanInsiden',
      pageTitle: 'Laporan Insiden',
      objName: 'Laporan Insiden',
    },
  },
  {
    path: 'dokter/:pageSlug?/:pageId?',
    name: 'Dokter',
    component: () => import('@/views/backend/Dokter/List.vue'),
    meta: {
      menu: 'Dokter',
      pageTitle: 'Dokter',
      objName: 'Dokter',
    },
  },
  {
    path: 'pasien/:pageSlug?',
    name: 'Pasien',
    component: () => import('@/views/backend/Pasien/List.vue'),
    meta: {
      menu: 'Pasien',
      pageTitle: 'Pasien',
      objName: 'Pasien',
    },
  },
  {
    path: 'rekam-medis/:pageSlug?/:rmNo?/:typeKajian?',
    name: 'RekamMedis',
    component: () => import('@/views/backend/RekamMedis/List.vue'),
    meta: {
      menu: 'RekamMedis',
      pageTitle: 'Rekam Medis',
      objName: 'Rekam Medis',
    },
  },
  {
    path: 'registrasi-pasien/:pageSlug?',
    name: 'RegistrasiPasien',
    component: () => import('@/views/backend/RegistrasiPasien/List.vue'),
    meta: {
      menu: 'RegistrasiPasien',
      pageTitle: 'Registrasi Pasien',
      objName: 'Registrasi Pasien',
    },
  },
  {
    path: 'farmasi/:pageSlug?',
    name: 'Farmasi',
    component: () => import('@/views/backend/Farmasi/List.vue'),
    meta: {
      menu: 'Farmasi',
      pageTitle: 'Farmasi',
      objName: 'Farmasi',
    },
  },
  {
    path: 'verifikasi-rekam-medis/:pageSlug?',
    name: 'VerifikasiRM',
    component: () => import('@/views/backend/VerifikasiRM/Form.vue'),
    meta: {
      menu: 'VerifikasiRM',
      pageTitle: 'Verifikasi Rekam Medis',
      objName: 'Verifikasi Rekam Medis',
    },
  },
  {
    path: 'laporan-management/:pageSlug?',
    name: 'LaporanManagement',
    component: () => import('@/views/backend/LaporanManagement/List.vue'),
    meta: {
      menu: 'LaporanManagement',
      pageTitle: 'Laporan Management',
      objName: 'Laporan Management',
    },
  },
  {
    path: 'master-hais/:pageSlug?',
    name: 'MHais',
    component: () => import('@/views/backend/MHais/List.vue'),
    meta: {
      menu: "MHais",
      pageTitle: 'Master Hais',
      hasCta: 'true',
      objName: 'Master Hais'
    },
  },
  {
    path: 'master-dosis/:pageSlug?',
    name: 'MDosis',
    component: () => import('@/views/backend/MDosis/List.vue'),
    meta: {
      menu: "MDosis",
      pageTitle: 'Master Dosis',
      hasCta: 'true',
      objName: 'Master Dosis'
    },
  },
  {
    path: 'follow-up/:pageSlug?',
    name: 'FollowUp',
    component: () => import('@/views/backend/FollowUp/Form.vue'),
    meta: {
      menu: "FollowUp",
      pageTitle: 'Follow Up',
      objName: 'Follow Up'
    },
  },
  {
    path: 'master-poli/:pageSlug?',
    name: 'MPoli',
    component: () => import('@/views/backend/MPoli/List.vue'),
    meta: {
      menu: "MPoli",
      pageTitle: 'Master Poli',
      hasCta: 'true',
      objName: 'Master Poli'
    },
  }, 
  {
    path: 'master-obat/:pageSlug?',
    name: 'MObat',
    component: () => import('@/views/backend/MObat/List.vue'),
    meta: {
      menu: "MObat",
      pageTitle: 'Master Obat',
      hasCta: 'true',
      objName: 'Master Obat'
    },
  },
  {
    path: 'master-alat-kesehatan/:pageSlug?',
    name: 'MAlatKesehatan',
    component: () => import('@/views/backend/MAlatKesehatan/List.vue'),
    meta: {
      menu: "MAlatKesehatan",
      pageTitle: 'Master Alat Kesehatan',
      hasCta: 'true',
      objName: 'Master Alat Kesehatan'
    },
  },
  {
    path: 'master-radiologi/:pageSlug?',
    name: 'MRadiologi',
    component: () => import('@/views/backend/MRadiologi/List.vue'),
    meta: {
      menu: "MRadiologi",
      pageTitle: 'Master Radiologi',
      hasCta: 'true',
      objName: 'Master Radiologi'
    },
  },
  {
    path: 'master-insiden/:pageSlug?',
    name: 'MInsiden',
    component: () => import('@/views/backend/MInsiden/List.vue'),
    meta: {
      menu: "MInsiden",
      pageTitle: 'Master Insiden',
      hasCta: 'true',
      objName: 'Master Insiden'
    },
  },
  {
    path: 'master-zipcode/:pageSlug?',
    name: 'MZipCode',
    component: () => import('@/views/backend/MZipCode/List.vue'),
    meta: {
      menu: "MZipCode",
      pageTitle: 'Master Zip Code',
      hasCta: 'true',
      objName: 'Master Zip Code'
    },
  },
  {
    path: 'master-diagnosa-gizi/:pageSlug?',
    name: 'MICDGizi',
    component: () => import('@/views/backend/MICDGizi/List.vue'),
    meta: {
      menu: "MICDGizi",
      pageTitle: 'Master Diagnosa Gizi',
      hasCta: 'true',
      objName: 'Master Diagnosa Gizi'
    },
  },
  {
    path: 'master-tindakan-gizi/:pageSlug?',
    name: 'MICDGiziTindakan',
    component: () => import('@/views/backend/MICDGiziTindakan/List.vue'),
    meta: {
      menu: "MICDGiziTindakan",
      pageTitle: 'Master Intervensi Gizi',
      hasCta: 'true',
      objName: 'Master Intervensi Gizi'
    },
  },
  {
    path: 'master-icd-9/:pageSlug?',
    name: 'MICD9',
    component: () => import('@/views/backend/MICD9/List.vue'),
    meta: {
      menu: "MICD9",
      pageTitle: 'Master ICD9',
      hasCta: 'true',
      objName: 'Master ICD9'
    },
  }, 
  {
    path: 'master-icd-10/:pageSlug?',
    name: 'MICD10',
    component: () => import('@/views/backend/MICD10/List.vue'),
    meta: {
      menu: "MICD10",
      pageTitle: 'Master ICD10',
      hasCta: 'true',
      objName: 'Master ICD10'
    },
  },
  {
    path: 'master-alat-bantu-tarik/:pageSlug?',
    name: 'MAlatBantuTarik',
    component: () => import('@/views/backend/MAlatBantuTarik/List.vue'),
    meta: {
      menu: "MAlatBantuTarik",
      pageTitle: 'Master Alat Bantu Tarik',
      hasCta: 'true',
      objName: 'Master Alat Bantu Tarik'
    },
  }, 
  {
    path: 'master-tindakan-fisioterapi/:pageSlug?',
    name: 'MTindakan',
    component: () => import('@/views/backend/MTindakan/List.vue'),
    meta: {
      menu: "MTindakan",
      pageTitle: 'Master Tindakan Fisioterapi',
      hasCta: 'true',
      objName: 'Master Tindakan Fisioterapi'
    },
  }, 
  {
    path: 'master-cara-pembayaran/:pageSlug?',
    name: 'MCaraPembayaran',
    component: () => import('@/views/backend/MCaraPembayaran/List.vue'),
    meta: {
      menu: "MCaraPembayaran",
      pageTitle: 'Master Cara Pembayaran',
      hasCta: 'true',
      objName: 'Master Cara Pembayaran'
    },
  },
  {
    path: 'master-kesadaran/:pageSlug?',
    name: 'MKesadaran',
    component: () => import('@/views/backend/MKesadaran/List.vue'),
    meta: {
      menu: "MKesadaran",
      pageTitle: 'Master Kesadaran',
      hasCta: 'true',
      objName: 'Master Kesadaran'
    },
  },
  {
    path: 'master-pekerjaan/:pageSlug?',
    name: 'MPendidikan',
    component: () => import('@/views/backend/MPendidikan/List.vue'),
    meta: {
      menu: "MPendidikan",
      pageTitle: 'Master Pendidikan',
      hasCta: 'true',
      objName: 'Master Pendidikan'
    },
  },
  {
    path: 'master-pekerjaan/:pageSlug?',
    name: 'MPekerjaan',
    component: () => import('@/views/backend/MPekerjaan/List.vue'),
    meta: {
      menu: "MPekerjaan",
      pageTitle: 'Master Pekerjaan',
      hasCta: 'true',
      objName: 'Master Pekerjaan'
    },
  },
  {
    path: 'master-laboratorium/:pageSlug?',
    name: 'MLab',
    component: () => import('@/views/backend/MLab/List.vue'),
    meta: {
      menu: "MLab",
      pageTitle: 'Master Tindakan Laboratorium',
      hasCta: 'true',
      objName: 'Master Tindakan Laboratorium'
    },
  },
  {
    path: 'master-sub-laboratorium/:pageSlug?',
    name: 'MSubLab',
    component: () => import('@/views/backend/MSubLab/List.vue'),
    meta: {
      menu: "MSubLab",
      pageTitle: 'Master Sub Laboratorium',
      hasCta: 'true',
      objName: 'Master Sub Laboratorium'
    },
  },
  {
    path: 'master-agama/:pageSlug?',
    name: 'MAgama',
    component: () => import('@/views/backend/MAgama/List.vue'),
    meta: {
      menu: "MAgama",
      pageTitle: 'Master Agama',
      hasCta: 'true',
      objName: 'Master Agama'
    },
  },
  {
    path: 'master-sdki/:pageSlug?',
    name: 'MSDKI',
    component: () => import('@/views/backend/MSDKI/List.vue'),
    meta: {
      menu: "MSDKI",
      pageTitle: 'Master Diagnosa SDKI',
      hasCta: 'true',
      objName: 'Master Diagnosa SDKI'
    },
  },
  {
    path: 'master-kategori-sdki/:pageSlug?',
    name: 'MKategoriSDKI',
    component: () => import('@/views/backend/MKategoriSDKI/List.vue'),
    meta: {
      menu: "MKategoriSDKI",
      pageTitle: 'Master Kategori SDKI',
      hasCta: 'true',
      objName: 'Master Kategori SDKI'
    },
  },
  {
    path: 'master-sub-kategori-sdki/:pageSlug?',
    name: 'MSubKategoriSDKI',
    component: () => import('@/views/backend/MSubKategoriSDKI/List.vue'),
    meta: {
      menu: "MSubKategoriSDKI",
      pageTitle: 'Master Sub Kategori SDKI',
      hasCta: 'true',
      objName: 'Master Sub Kategori SDKI'
    },
  },
  {
    path: 'master-slki/:pageSlug?',
    name: 'MSLKI',
    component: () => import('@/views/backend/MSLKI/List.vue'),
    meta: {
      menu: "MSLKI",
      pageTitle: 'Master Diagnosa SLKI',
      hasCta: 'true',
      objName: 'Master Diagnosa SLKI'
    },
  },
  {
    path: 'master-kriteria-hasil-slki/:pageSlug?',
    name: 'MKriteriaSLKI',
    component: () => import('@/views/backend/MKriteriaSLKI/List.vue'),
    meta: {
      menu: "MKriteriaSLKI",
      pageTitle: 'Master Kriteria SLKI',
      hasCta: 'true',
      objName: 'Master Kriteria SLKI'
    },
  },
  {
    path: 'master-siki/:pageSlug?',
    name: 'MSIKI',
    component: () => import('@/views/backend/MSIKI/List.vue'),
    meta: {
      menu: "MSIKI",
      pageTitle: 'Master SIKI',
      hasCta: 'true',
      objName: 'Master SIKI'
    },
  },
  {
    path: 'master-type-satuan/:pageSlug?',
    name: 'MTypeSatuan',
    component: () => import('@/views/backend/MTypeSatuan/List.vue'),
    meta: {
      menu: "MTypeSatuan",
      pageTitle: 'Master Type Satuan',
      hasCta: 'true',
      objName: 'Master Type Satuan'
    },
  },
  {
    path: 'master-fakes-rujukan/:pageSlug?',
    name: 'MRoFakesRujukan',
    component: () => import('@/views/backend/MRoFakesRujukan/List.vue'),
    meta: {
      menu: "MRoFakesRujukan",
      pageTitle: 'Master Fakes Rujukan',
      hasCta: 'true',
      objName: 'Master Fakes Rujukan'
    },
  },
  {
    path: 'master-poli-rujukan/:pageSlug?',
    name: 'MRoPoliRujukan',
    component: () => import('@/views/backend/MRoPoliRujukan/List.vue'),
    meta: {
      menu: "MRoPoliRujukan",
      pageTitle: 'Master Poli Rujukan',
      hasCta: 'true',
      objName: 'Master Poli Rujukan'
    },
  },
  {
    path: 'master-dokter-rujukan/:pageSlug?',
    name: 'MRoDokterRujukan',
    component: () => import('@/views/backend/MRoDokterRujukan/List.vue'),
    meta: {
      menu: "MRoDokterRujukan",
      pageTitle: 'Master Dokter Rujukan',
      hasCta: 'true',
      objName: 'Master Dokter Rujukan'
    },
  },
  {
    path: 'master-suku/:pageSlug?',
    name: 'MRoSuku',
    component: () => import('@/views/backend/MRoSuku/List.vue'),
    meta: {
      menu: "MRoSuku",
      pageTitle: 'Master Suku',
      hasCta: 'true',
      objName: 'Master Suku'
    },
  },
  {
    path: 'master-riwayat-alergi/:pageSlug?',
    name: 'MRiwayatAlergi',
    component: () => import('@/views/backend/MRiwayatAlergi/List.vue'),
    meta: {
      menu: "MRiwayatAlergi",
      pageTitle: 'Master Riwayat Alergi',
      hasCta: 'true',
      objName: 'Master Riwayat Alergi'
    },
  },

  {
    path: 'account-setting',
    name: 'AccountSetting',
    component: () => import('@/views/backend/AccountSetting.vue'),
    meta: {
      menu: 'BoAccount',
      pageTitle: 'Account Setting',
      objName: 'Account',      
    },
  },
  {
    path: 'user/:pageSlug?',
    name: 'Users',
    component: () => import('@/views/backend/User/List.vue'),
    meta: {
      menu: "Users",
      pageTitle: 'Users',
      hasCta: 'true',
      objName: 'User'
    },
  },
  {
    path: 'user-level/:pageSlug?',
    name: 'UserLevel',
    component: () => import('@/views/backend/UserLevel/List.vue'),
    meta: {
      menu: 'UserLevel',
      pageTitle: 'User Level',
      hasCta: 'true',
      objName: 'User Level'
    },
  },
  {
    path: 'audit-trail/:pageSlug?',
    name: 'AuditTrail',
    component: () => import('@/views/backend/AuditTrail/AuditTrail.vue'),
    meta: {
      menu: 'AuditTrail',
      pageTitle: 'Audit Trail',
      objName: 'Audit Trail'
    },
  },

  // pendaftaran online
  {
    path: 'admisi-pasien/:pageSlug?',
    name: 'RoPasien',
    component: () => import('@/views/backend/RoPasien/List.vue'),
    meta: {
      menu: 'RoPasien',
      pageTitle: 'Admisi - Pasien',
      objName: 'Admisi - Pasien',
    },
  },

  {
    path: 'admisi-reservasi/:pageSlug?',
    name: 'RoReservasi',
    component: () => import('@/views/backend/RoReservasi/List.vue'),
    meta: {
      menu: 'RoReservasi',
      pageTitle: 'Admisi - Reservasi',
      objName: 'Admisi - Reservasi'
    },
  },

  
  {
    path: 'audio-antrean/:pageSlug?',
    name: 'AntreanRoAudio',
    component: () => import('@/views/backend/AntreanRoAudio/List.vue'),
    meta: {
      menu: 'AntreanRoAudio',
      pageTitle: 'Antrean - Audio',
      objName: 'Antrean - Audio',
      hasCta: 'true',
    },
  },
  {
    path: 'unit-tujuan/:pageSlug?',
    name: 'AntreanRoUnit',
    component: () => import('@/views/backend/AntreanRoUnit/List.vue'),
    meta: {
      menu: 'AntreanRoUnit',
      pageTitle: 'Antrean - Unit Tujuan',
      objName: 'Antrean - Unit Tujuan',
      hasCta: 'true',
    },
  },
  {
    path: 'group-antrean/:pageSlug?',
    name: 'AntreanRoGroup',
    component: () => import('@/views/backend/AntreanRoGroup/List.vue'),
    meta: {
      menu: 'AntreanRoGroup',
      pageTitle: 'Antrean - Group Antrean',
      objName: 'Antrean - Group Antrean',
      hasCta: 'true',
    },
  },
  {
    path: 'display-layar/:pageSlug?',
    name: 'AntreanRoDisplayLayar',
    component: () => import('@/views/backend/AntreanRoDisplayLayar/List.vue'),
    meta: {
      menu: 'AntreanRoDisplayLayar',
      pageTitle: 'Antrean - Display Antrean',
      objName: 'Antrean - Display Antrean',
    },
  },
  {
    path: 'setting-layar/:pageSlug?',
    name: 'AntreanRoSettingLayar',
    component: () => import('@/views/backend/AntreanRoSettingLayar/List.vue'),
    meta: {
      menu: 'AntreanRoSettingLayar',
      pageTitle: 'Antrean - Setting Antrean',
      objName: 'Antrean - Setting Antrean',
      hasCta: 'true',
    },
  },
  {
    path: 'jadwal-dokter',
    name: 'RoJadwalDokter',
    component: () => import('@/views/backend/RoJadwalDokter/List.vue'),
    meta: {
      menu: 'RoJadwalDokter',
      pageTitle: 'Jadwal Dokter',
      objName: 'Jadwal Dokter',
    },
  },
  {
    path: 'rujukan/:pageSlug?',
    name: 'RoRujukan',
    component: () => import('@/views/backend/RoRujukan/List.vue'),
    meta: {
      menu: 'RoRujukan',
      pageTitle: 'Rujukan',
      objName: 'Rujukan'
    },
  },
  {
    path: 'admisi-antrean',
    name: 'RoAdmisiAntrean',
    component: () => import('@/views/backend/RoAdmisiAntrean/List.vue'),
    meta: {
      menu: 'RoAdmisiAntrean',
      pageTitle: 'Admisi Antrean',
      objName: 'Admisi Antrean',
    },
  },
  {
    path: 'billing',
    name: 'RoBilling',
    component: () => import('@/views/backend/RoBilling/List.vue'),
    meta: {
      menu: 'RoBilling',
      pageTitle: 'Billing',
      objName: 'Billing',
    },
  },
  {
    path: 'Notifikasi',
    name: 'Notification',
    component: () => import('@/views/backend/Notification/List.vue'),
    meta: {
      menu: 'Notification',
      pageTitle: 'Notifikasi',
      objName: 'Notifikasi',
    },
  },

  {
    path: 'bpjs-peserta',
    name: 'BPJSPeserta',
    component: () => import('@/views/backend/BPJSPeserta/List.vue'),
    meta: {
      menu: 'BPJSPeserta',
      pageTitle: 'BPJS Peserta',
      objName: 'BPJS Peserta',
    },
  },

  {
    path: 'bpjs-referensi',
    name: 'BPJSReferensi',
    component: () => import('@/views/backend/BPJSReferensi/List.vue'),
    meta: {
      menu: 'BPJSReferensi',
      pageTitle: 'BPJS Referensi',
      objName: 'BPJS Referensi',
    },
  },

  {
    path: 'bpjs-monitoring',
    name: 'BPJSMonitoring',
    component: () => import('@/views/backend/BPJSMonitoring/List.vue'),
    meta: {
      menu: 'BPJSMonitoring',
      pageTitle: 'BPJS Monitoring',
      objName: 'BPJS Monitoring',
    },
  },

  {
    path: 'bpjs-sep',
    name: 'BPJSSEP',
    component: () => import('@/views/backend/BPJSSEP/List.vue'),
    meta: {
      menu: 'BPJSSEP',
      pageTitle: 'BPJS SEP',
      objName: 'BPJS SEP',
    },
  },

  {
    path: 'bpjs-referensi-antrean',
    name: 'BPJSRefrensiAntrean',
    component: () => import('@/views/backend/BPJSRefrensiAntrean/List.vue'),
    meta: {
      menu: 'BPJSRefrensiAntrean',
      pageTitle: 'BPJS Referensi Antrean',
      objName: 'BPJS Referensi Antrean',
    },
  },
  
  {
    path: 'bpjs-rujukan',
    name: 'BPJSRujukan',
    component: () => import('@/views/backend/BPJSRujukan/List.vue'),
    meta: {
      menu: 'BPJSRujukan',
      pageTitle: 'BPJS Rujukan',
      objName: 'BPJS Rujukan',
    },
  },

  {
    path: 'bpjs-prb',
    name: 'BPJSPRB',
    component: () => import('@/views/backend/BPJSPRB/List.vue'),
    meta: {
      menu: 'BPJSPRB',
      pageTitle: 'BPJS PRB',
      objName: 'BPJS PRB',
    },
  },

  {
    path: 'bpjs-rencana-kontrol',
    name: 'BPJSRencanaKontrol',
    component: () => import('@/views/backend/BPJSRencanaKontrol/List.vue'),
    meta: {
      menu: 'BPJSRencanaKontrol',
      pageTitle: 'BPJS Rencana Kontrol',
      objName: 'BPJS Rencana Kontrol',
    },
  },

  {
    path: 'bpjs-data-sep/:pageSlug?',
    name: 'BPJSDataSEP',
    component: () => import('@/views/backend/BPJSDataSEP/List.vue'),
    meta: {
      menu: 'BPJSDataSEP',
      pageTitle: 'BPJS Data SEP',
      objName: 'BPJS Data SEP',
      hasCta: 'true',
    },
  },

  {
    path: 'bpjs-lpk/:pageSlug?',
    name: 'BPJSLPK',
    component: () => import('@/views/backend/BPJSLPK/List.vue'),
    meta: {
      menu: 'BPJSLPK',
      pageTitle: 'BPJS Data LPK',
      objName: 'BPJS Data LPK',
      hasCta: 'true',
    },
  },
  // pendaftaran online

  

  
  
]
export default bo_routes